import React from 'react';
import ReactDOM from 'react-dom';

function getBuildTarget() {
  switch (process.env.REACT_APP_BUILD_MODE) {
    case 'chat':
      return import('./distributions/ChatV2');
    // case 'chat':
    //   return import('./distributions/chat');
    case 'vdp':
      return import('./distributions/vdp');
    case 'component':
      return import('./distributions/component');
    case 'vdp2':
      return import('./distributions/vdp');
      default:
      return import('./distributions/web');
  }
}

getBuildTarget()
  .then(({ default: Environment }) => {
    ReactDOM.render(
      <React.StrictMode>
        <Environment />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  })
  .catch((error) => console.error(error));
